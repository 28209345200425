import { Auth, Storage } from 'aws-amplify';
import { nanoid } from 'nanoid';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { updateCrackImage, updateLocationImage, updateOverviewImage, updatePipeImage } from 'src/api';
import {
  AvatarImage,
  CrackImage,
  ImageOther,
  ImageType,
  ImageVault,
  isImageOther,
  isImageVault,
  LocationImage,
  OverviewImage,
  PipeImage,
  S3Image
} from 'src/models';

interface IS3ImageProps {
  image: S3Image;
  download?: boolean;
}

interface IUploadS3ImageProps {
  image: S3Image;
  file: File;
}

const updateImageVault = async (image: ImageVault, fileName: string, fileExt: string) => {
  if (!isImageVault(image)) return;
  switch (image.type) {
    case ImageType.OVERVIEW:
      return updateOverviewImage({
        input: {
          asset: (image as OverviewImage).id,
          idx: (image as OverviewImage).idx,
          relation: (image as OverviewImage).relation,
          name: fileName,
          ext: fileExt
        }
      });
    case ImageType.LOCATION:
      return updateLocationImage({
        input: {
          controlUnit: (image as LocationImage).id,
          relation: (image as LocationImage).relation,
          name: fileName,
          ext: fileExt
        }
      });
    case ImageType.CRACK:
      return updateCrackImage({
        input: {
          strip: (image as CrackImage).id,
          name: fileName,
          ext: fileExt
        }
      });
    case ImageType.PIPE:
      return updatePipeImage({
        input: {
          strip: (image as PipeImage).id,
          name: fileName,
          ext: fileExt
        }
      });
    default:
      console.warn(`ImageType: ${image.type} not supported for update operation`);
  }
};

const updateImageOther = async (image: ImageOther, fileKey: string) => {
  if (!isImageOther(image)) return;
  switch (image.type) {
    case ImageType.AVATAR:
      return Auth.updateUserAttributes((image as AvatarImage).user, { picture: fileKey });
    default:
      console.warn(`ImageType: ${image.type} not supported for update operation`);
  }
};

export const getS3FileKey = (image: ImageVault) =>
  image.name.includes('default')
    ? `${image.type.toLowerCase()}/${image.name}.${image.ext}`
    : `${image.type.toLowerCase()}/${image.id}_${image.name}.${image.ext}`;

export const setS3FileKey = (image: ImageVault, name: string, ext: string) =>
  `${image.type.toLowerCase()}/${image.id}_${name}.${ext}`;

// TODO: add dispatch to modify/refetch devices
export const uploadS3Image = async ({ image, file }: IUploadS3ImageProps) => {
  if (!image || !file) return;
  if (isImageOther(image)) {
    const fileKey = file.name;
    Storage.put(fileKey, file, {
      level: 'private',
      contentType: file.type
    }).then(() => updateImageOther(image, fileKey));
    return fileKey;
  }
  if (isImageVault(image)) {
    const fileName = nanoid();
    const fileExt = file.name.split('.').pop();
    const fileKey = setS3FileKey(image, fileName, fileExt)
    await Storage.put(fileKey, file, {
      level: 'public',
      contentType: file.type
    }).then(() => updateImageVault(image, fileName, fileExt));
    return fileKey;
  }
};

export const getImageFromStorage = async (
  fileKey: string,
  level: 'public' | 'private',
  download: boolean,
  setState?: Dispatch<SetStateAction<string>>
): Promise<string | null> => {
  if (!fileKey) return null;

  let data = null;

  try {
    const result = await Storage.get(fileKey, { level, download });
    if (download) {
      const blob = (result as unknown as { Body: any })?.Body;
      if (blob) data = URL.createObjectURL(blob);
    } else {
      data = result;
    }
  } catch (err) {
    console.error(`Failed to get img: ${fileKey}`, err);
    data = null;
  }

  if (setState) setState(data);
  return data;
};

const useS3Image = ({ image, download = true }: IS3ImageProps) => {
  const [s3Image, setS3Image] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      if (isImageOther(image)) {
        await getImageFromStorage(image.fileKey, 'private', download, setS3Image);
        return;
      }

      if (isImageVault(image)) {
        await getImageFromStorage(getS3FileKey(image), 'public', download, setS3Image);
        return;
      }

      return setS3Image(null);
    };

    loadImage();
  }, [image, download]);

  return s3Image;
};

export default useS3Image;

import { GridComparatorFn } from '@mui/x-data-grid';
import { Activity, ControlUnit, ControlUnitReport, DashboardItem, Strip, StripReport } from 'src/models';
import { activityPriority, activitySortOrder } from './activity';

// Model Comparators
/* prettier-ignore */ export const cUnitComparator = (a: ControlUnit, b: ControlUnit) => a.id === b.id ? 0 : (a.id > b.id ? 1 : -1);
/* prettier-ignore */ export const stripComparator = (a: Strip, b: Strip) => a.id === b.id ? 0 : (a.id > b.id ? 1 : -1);
/* prettier-ignore */ export const stripReportComparator = (a: StripReport, b: StripReport) => a.id === b.id ? 0 : (a.id > b.id ? 1 : -1);
/* prettier-ignore */ export const cUnitReportComparator = (a: ControlUnitReport, b: ControlUnitReport) => a.id === b.id ? 0 : (a.id > b.id ? 1 : -1);
/* prettier-ignore */ export const zoneComparator = (a: string, b: string) => a === b ? 0 : (parseInt(a) > parseInt(b) ? 1 : -1);
/* prettier-ignore */ export const dashboardItemComparator = (a: DashboardItem, b: DashboardItem) => cUnitComparator(a.controlUnit, b.controlUnit);
/* prettier-ignore */ export const activityPriorityComparator = (a: Activity, b: Activity) => activityPriority[a] === activityPriority[b] ? 0 : (activityPriority[a] > activityPriority[b] ? -1 : 1);

// Grid Sort Comparators
/* prettier-ignore */ export const dateIsoSortComparator: GridComparatorFn<string> = (a: string, b: string, aRow: any, bRow: any): number => a === b ? -stripReportComparator(aRow, bRow) : new Date(a).getTime() > new Date(b).getTime() ? 1 : -1;
/* prettier-ignore */ export const activitySortComparator: GridComparatorFn<Activity> = (a: Activity, b: Activity, aRow: any, bRow: any) => activitySortOrder[a] === activitySortOrder[b] ? -stripReportComparator(aRow, bRow) : (activitySortOrder[a] > activitySortOrder[b] ? 1 : -1);
/* prettier-ignore */ export const numericValueSortComparator: GridComparatorFn<number> = (a: number, b: number, aRow: any, bRow: any) => a === b ? -cUnitReportComparator(aRow, bRow) : (a > b ? 1 : -1);

import { useEffect, useState } from 'react';

import { fetchReports } from 'src/api';
import { Report } from 'src/models';

export type ReportState<ReportType extends Report> = Record<ReportType['id'], ReportType>;

export const getInitReportState = <ReportType extends Report>(): ReportState<ReportType> =>
  ({} as Record<ReportType['id'], ReportType>);

const reportsHookFactory = <T extends Report['__typename']>(type: T) => {
  return <ReportType extends Extract<Report, { __typename: T }>>(id: string): ReportState<ReportType> => {
    const [state, setState] = useState<ReportState<ReportType>>(getInitReportState<ReportType>());

    useEffect(() => {
      if (!id) return;

      setState({} as ReportState<ReportType>);

      const fetchId = type === 'AnalysisReport' ? `${id}%` : id;

      fetchReports({ type, id: fetchId }).then((reports) => {
        if (reports.kind === 'SUCCESS' && reports.data)
          setState(
            reports.data.reduce((records, report) => {
              records[report.id] = report as ReportType;
              return records;
            }, {} as ReportState<ReportType>)
          );
      });
    }, [id]);

    return state;
  };
};

export const useAssetReports = reportsHookFactory('AssetReport');
export const useControlUnitReports = reportsHookFactory('ControlUnitReport');
export const useStripReports = reportsHookFactory('StripReport');
export const useAnalysisReports = reportsHookFactory('AnalysisReport');

import _ from 'lodash';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useDashboardState, { DashboardState } from 'src/hooks/DashboardStateHook';
import { getImageFromStorage, getS3FileKey } from 'src/hooks/ImageLoaderHook';
import { ReportState, useControlUnitReports, useStripReports } from 'src/hooks/ReportHooks';
import {
  Asset,
  BusinessUnit,
  ControlUnit,
  ControlUnitReport,
  Customer,
  Partner,
  Strip,
  StripReport
} from 'src/models';

import { useUser } from './UserContext';
import { OverviewImageData } from 'src/models/S3Image';

export enum Tab {
  OVERVIEW = 'overview',
  REPORT = 'report',
  DETAILS = 'details'
}

interface IDashboardContext {
  dashboard: DashboardState;

  item: {
    partner: Partner | undefined;
    customer: Customer | undefined;
    businessUnit: BusinessUnit | undefined;
    asset: Asset | undefined;
  };

  report: {
    controlUnits: ReportState<ControlUnitReport>;
    strips: ReportState<StripReport>;
  };

  image: {
    overviews: OverviewImageData[];
    getOverview: (cUnit: ControlUnit) => OverviewImageData;
    // setOverview: typeof api.setOverviewImage;
    // setLocation: typeof api.setLocationImage;
    // setCrack: typeof api.setCrackImage;
  };

  tab: {
    active: string | undefined;
    switch: (tab: Tab) => void;
    overview: () => void;
    report: () => void;
    filteredReport: (id?: ControlUnit['id']) => void;
    details: (id?: Strip['id']) => void;
  };

  select: {
    controlUnit: (id: ControlUnit['id']) => void;
    strip: (id: Strip['id']) => void;
  };

  selected: {
    controlUnit: ControlUnit | undefined;
    strip: Strip | undefined;
    isFiltered: boolean;
  };
}

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

const DashboardProvider: FC = ({ children }) => {
  const { menu } = useUser();

  // PATH
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const setParams = useCallback(
    (params: Record<string, string>) =>
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        Object.entries(params).forEach(([key, value]) => newParams.set(key, value));
        return newParams;
      }),
    [setSearchParams]
  );
  const stripId = useMemo(() => searchParams.get('select'), [searchParams]);
  const tabId = useMemo(() => searchParams.get('tab') ?? Tab.OVERVIEW, [searchParams]);
  const isFiltered = useMemo(() => searchParams.get('filter') === 'true', [searchParams]);
  const navigate = useNavigate();

  // ITEMS
  const asset = useMemo(() => menu.assets[id], [menu, id]);
  const businessUnit = useMemo(() => menu.businessUnits[asset?.businessUnit], [menu, asset]);
  const customer = useMemo(() => menu.customers[asset?.customer], [menu, asset]);
  const partner = useMemo(() => menu.partners[asset?.partner], [menu, asset]);

  // API
  const dashboard = useDashboardState(asset);
  const stripReports = useStripReports(asset?.id);
  const controlUnitReports = useControlUnitReports(asset?.id);

  // SELECTION
  const strip = useMemo(() => dashboard.strips[stripId], [dashboard.strips, stripId]);
  const controlUnit = useMemo(
    () => dashboard.controlUnits[strip?.controlUnit],
    [dashboard.controlUnits, strip]
  );
  const selectControlUnit = useCallback(
    (id: ControlUnit['id']) => setParams({ select: dashboard.treeIndex[id]?.at(0) }),
    [setParams]
  );
  const selectStrip = useCallback((id: Strip['id']) => setParams({ select: id }), [setParams]);

  // TAB NAVIGATION
  const switchOverview = useCallback(() => setParams({ tab: Tab.OVERVIEW }), [setParams]);
  const switchReport = useCallback(() => setParams({ tab: Tab.REPORT, filter: 'false' }), [setParams]);
  const switchFilteredReport = useCallback(
    (id?: ControlUnit['id']) =>
      setParams({ tab: Tab.REPORT, select: dashboard.treeIndex[id]?.at(0), filter: 'true' }),
    [setParams]
  );
  const switchDetails = useCallback(
    (id?: Strip['id']) => setParams({ tab: Tab.DETAILS, select: id ?? stripId }),
    [setParams]
  );
  const switchTab = (tab: Tab) => {
    switch (tab) {
      case Tab.DETAILS:
        return switchDetails();
      case Tab.REPORT:
        return switchReport();
      case Tab.OVERVIEW:
        return switchOverview();
    }
  };
  // Navigation
  useEffect(() => {
    if (_.isEmpty(menu.assets)) return;
    if (!asset) navigate('/home', { replace: true });

    if (_.isEmpty(dashboard.tree)) return;
    if (!stripId || !strip) selectStrip(dashboard.tree[0].strips[0]);
    if (!tabId) switchOverview();
  }, [menu.assets, asset, dashboard.tree, strip, stripId, tabId]);

  // Images
  const [overviewImages, setOverviewImages] = useState<OverviewImageData[] | null>(null);

  const getOverview = (cUnit: ControlUnit) =>
    overviewImages?.filter((img) => img.relation === cUnit.location.relation)[0] ?? null;

  const loadOverviewImages = (images: Asset['overviews']) => {
    Promise.all(images.map((img) => getImageFromStorage(getS3FileKey(img), 'public', true))).then((urls) => {
      setOverviewImages(
        urls.map((url, index) => {
          const image = images[index];
          return {
            ...image,
            url,
            title: image.relation.toUpperCase(),
            component: <img src={url} alt={image.relation} />
          };
        })
      );
    });
  };

  // Load overview images
  useEffect(() => {
    loadOverviewImages(asset?.overviews ?? []);
  }, [asset]);

  return (
    <DashboardContext.Provider
      value={{
        dashboard,

        item: {
          partner,
          customer,
          businessUnit,
          asset
        },

        report: {
          controlUnits: controlUnitReports,
          strips: stripReports
        },

        image: {
          overviews: overviewImages,
          getOverview
          // setOverview: api.setOverviewImage
          // setLocation: api.setLocationImage,
          // setCrack: api.setCrackImage,
        },

        tab: {
          active: tabId,
          switch: switchTab,
          overview: switchOverview,
          report: switchReport,
          filteredReport: switchFilteredReport,
          details: switchDetails
        },

        select: {
          controlUnit: selectControlUnit,
          strip: selectStrip
        },

        selected: {
          controlUnit,
          strip,
          isFiltered
        }
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = (): IDashboardContext => useContext(DashboardContext);

export default DashboardProvider;
